/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    font-family: ff("sans");
    color: $font-color;
}


/*==============================
=            Titles            =
==============================*/

.t-cms h1, .t-cms h2, .t-cms h3,
.t-t1, .t-t2, .t-t3 {
    line-height: 1.1;
    font-family: ff("serif");
    font-weight: 300;
}

.t-t1-sans,
.t-t2-sans,
.t-t3-sans {
    font-family: ff("sans");
    font-weight: 400;
}

.t-cms h1,
.t-t1,
.t-t1-sans {
    line-height: 1.4;
    @include responsive-type($min-font: 40, $max-font: 132, $min-width: 320, $max-width: 1920);
}

.t-cms h2,
.t-t2,
.t-t2-sans {
    @include responsive-type($min-font: 30, $max-font: 72, $min-width: 320, $max-width: 1920);
}

.t-cms h3,
.t-t3,
.t-t3-sans {
    @include responsive-type($min-font: 24, $max-font: 45, $min-width: 320, $max-width: 1920);
}

.t-cms h4,
.t-cms h5,
.t-t4, .t-t5, .t-small {
    font-family: ff("sans");
    font-weight: 400;
    color: $color-dark;
}

.t-cms h4,
.t-cms h5,
.t-t4, .t-t5 {
    text-transform: uppercase;
}

.t-cms h4,
.t-t4 {
    font-size: 1.1em;

    @media #{md("sm")} {
        font-size: 1.25em;
    }
}

.t-cms h5,
.t-t5,
.t-small, {
    font-size: .75em;

    @media #{md("sm")} {
        font-size: .875em;
    }
}

.t-big {
    line-height: 1.1;
    font-family: ff("sans");
    font-weight: 400;
    @include responsive-type($min-font: 28, $max-font: 60, $min-width: 320, $max-width: 1920);
    text-transform: uppercase;
}


/*==========================================
=            CMS content styles            =
==========================================*/


.t-cms {

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 1.414em 0 0.5em;
    }

    a {
        display: inline-block;
        color: $color-grey;
        text-decoration: underline;
        transition: opacity .2s ease-out;

        &:hover {
            opacity: .7;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/


.t-inline-link {
    display: inline;
    background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: background-size .4s;
    transition: color .4s ease-out, background-size .4s $in-out-cubic;

    &:hover {
        color: $color-dark;
        background-size: 100% 100%;
    }
}


small {
    font-size: 0.75em;
}

sup {
    vertical-align: super;
    font-size: 0.75em;
}

a {
    color: inherit;
    text-decoration: none;
}
