
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
































































/*----------  Tags list  ----------*/

.o-tags {
    --reveal-end: 1;

    margin: .2em -.3em;


    &.js-reveal {

        .o-tags__item {
            opacity: 0;
            transform: translate(0, 1em) scale(1, .5);
            transform-origin: 50% 100%;
        }

        &.is-visible {

            .o-tags__item {
                opacity: 1;
                transform: scale(1);
                transition: opacity .6s ease-out .4s;
            }
        }
    }
}

.o-tags__item {
    margin: .3em;
}


/*----------  Tag  ----------*/

.o-tag {
    display: inline-block;
    padding-right: .2em;
    padding-left: .2em;
    color: $color-dark;
    background-color: $color-blue-light;
    transition: background-color .2s ease-out;

    &.-single {
        margin-top: .5em;
        margin-bottom: .5em;
    }

    &:hover {
        background-color: $color-peach;
    }
}

.o-tag__link {

}

