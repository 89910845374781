
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                































































































































.o-input {
    color: $color-dark;

    &.-text {

        &:before {
            @include pseudo-el($bg: $color-beige);
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1, 0);
            transform-origin: 50% 100%;
            transition: transform .2s ease-out;
        }

        &:after {
            @include pseudo-el($height: 1px, $bg: $color-dark);
            position: absolute;
            bottom: 0;
            left: 0;
            transition: background-color .2s ease-out;
        }
    }

    &.is-filled {

        &:before {
            transform: scale(1);
        }

        &:after {
            background-color: $color-grey;
        }

        .o-input__label .o-at__w-inner {
            animation: at-word-out-down .2s ease-out forwards;
        }
    }

    &.is-disabled {
        pointer-events: none;

        &:before {
            background-color: $color-grey;
        }

        &:after {
            background-color: transparent;
        }

        .o-input__input {
            color: $color-light;
        }
    }
}

.o-input__input {
    width: 100%;
    padding: 1em .75em;
    color: $color-grey;
}

.o-input__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: .5em;
    pointer-events: none;

    .o-at__w-inner {
        animation: at-word-in-down .2s ease-out forwards;
    }
}

.o-input__reset {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25em;
    height: 100%;
    color: $color-grey;
}

