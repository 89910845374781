
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                









































































































































































































































































// .p-photo {}

.p-photo__title {
    max-height: 4em;
    overflow: hidden;

    @media #{md("sm", "max")} {
        margin-left: calc(#{$header-size} - var(--grid-gutter) * 2);
        padding-top: .4em;
        padding-bottom: .2em;
    }

    @media #{md("sm")} {
        position: absolute;
        top: .1em;
        right: 0;
        left: 0;
        justify-content: center;
        width: auto;
        padding-right: 8rem;
        padding-left: 8rem;
    }
}

// .p-photo__date {
//     --anim-delay: .6s;

//     position: absolute;
//     left: 0;
//     width: 100%;

//     @media #{md("sm", "max")} {
//         bottom: -.75em;
//     }

//     @media #{md("sm")} {
//         top: 50%;
//     }
// }

.p-photo__asset {
    width: 75vw;
    margin-right: auto;
    margin-left: auto;

    @media #{md("sm", "max")} {
        margin-bottom: 2em;
    }

    @media #{md("sm")} {
        width: 35vw;
        padding-top: 10vh;
    }
}


.p-photo__fund {
    margin-top: 10vh;
}

.p-photo__fund-title {
    top: .6em;
}


/*----------  Data  ----------*/

.o-data__item {
    margin-top: 2em;

    &.-btn {
        margin-bottom: 4em;
    }

    &.-infos,
    &.-contact {
        padding: 1em;
        color: $color-dark;
    }

    &.-infos {
        background-color: $color-beige;
        transition: background-color .3s ease-out;

        &.is-hovering {
            background-color: $color-peach;
        }
    }

    &.-contact {
        background-color: $color-blue-light;

        a {
            color: $color-blue;
        }
    }

    @media #{md("xs", "max")} {

        &.-infos {

            .c-btn {
                margin-top: 1em;
            }
        }
    }

    @media #{md("xs")} {

        &.-infos {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .c-btn {
                margin-left: 1em;
                white-space: nowrap;
            }
        }
    }
}


/*----------  Table  ----------*/

.o-table {
    display: flex;
    flex-wrap: wrap;
}

.o-table__item {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;
    padding-top: .5em;
    padding-right: .5em;
    padding-bottom: .5em;

    @media #{md("xs", "max")} {
        min-width: 50%;

        &:nth-child(n+3) {
            margin-top: 1em;
        }

        &:nth-child(2n) {
            border-left: 1px solid $color-dark;
            padding-left: .5em;
        }
    }

    @media #{md("xs")} {

        &:nth-child(n+2) {
            border-left: 1px solid $color-dark;
            padding-left: .5em;
        }
    }
}

.o-table__label {
    margin-bottom: 1.5em;
}

