
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                








































































.o-btn-return {
    z-index: 1;
    position: absolute;
    top: 0;
    top: calc((#{$header-size} - 2.75em)/2);
    left: calc((#{$header-size} - 2.75em)/2 - var(--grid-gutter));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    height: 2.75em;
    opacity: 0;

    &.is-visible {
        opacity: 1;
        transition: opacity .4s ease-out 1s;
    }

    @media #{md("sm", "max")} {

        .c-btn {
            display: none;
        }
    }

    @media #{md("sm")} {
        top: 0;
        left: var(--grid-gutter);
        width: auto;
        height: $header-size;
        align-items: flex-end;

        > .o-btn-circle {
            display: none;
        }
    }
}

