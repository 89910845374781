/*============================
=            Main            =
============================*/

.l-wrapper {

    @media #{md("sm", "max")} {
        // padding-top: $header-size;
        top: $header-size;
        height: calc(100% - #{$header-size});
        overflow-y: auto;
    }

    @media #{md("sm")} {
        padding-right: $header-size;
        padding-left: $header-size;
    }
}

.l-wrapper__inner {

    @media #{md("sm", "max")} {
        min-height: 100%;
    }
}

/*
.l-wrapper__inner {
    transition: opacity .3s ease-out $grid-layout-duration-out;

    .nav-is-open & {
        opacity: 0;
        transition: opacity $grid-layout-duration-in/3 ease-in 0s;
    }
}
*/


.l-main {
    // padding-bottom: 5vh;

    @media #{md("sm", "max")} {
        padding-right: $grid-gutter--mobile;
        padding-left: $grid-gutter--mobile;
    }
}

.l-content {

    @media #{md("sm")} {
        padding-bottom: 10vh;
    }
}
