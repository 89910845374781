
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






























































































.c-slider {
    --reveal-end: 1.6;

    left: -$grid-gutter--mobile;
    width: 100vw;
    display: block;
    overflow: hidden;

    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    // Hide scrollbars, works well with Flickity without downside.
    &::-webkit-scrollbar {
        display: none;
    }

    .flickity-viewport {
        overflow: visible;
    }

    &.js-reveal {

        .o-card {
            overflow: hidden;

            .o-card__inner {
                transform: translate(calc(100% + var(--grid-gutter-half)), 0) scale(.75, 1);
                transform-origin: 100% 50%;
                will-change: transform;

                &:after {
                    @include pseudo-el($bg: $color-light);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &.is-visible {

            .o-card {

                .o-card__inner {
                    transform: translate(0) scale(1);

                    &:after {
                        opacity: 0;
                    }
                }

                @for $i from 1 through 2 {

                    &:nth-child(#{$i}) .o-card__inner {
                        $i: $i - 1;
                        transition: transform #{.8 + $i/(2 * 2)}s $out-expo #{$i/(2 * 4)}s;

                        &:after {
                            transition: opacity #{.8 + $i/(2 * 2)}s ease-out #{$i/(2 * 4)}s
                        }
                    }
                }
            }
        }
    }

    @media #{md("sm", "max")} {
        padding-right: var(--grid-gutter);
        padding-left: var(--grid-gutter);
    }

    @media #{md("sm")} {
        left: 1px;
        width: calc(100vw - #{$header-size} - 1px);

        &.js-reveal.is-visible {

            .o-card {

                @for $i from 1 through 4 {

                    &:nth-child(#{$i}) .o-card__inner {
                        $i: $i - 1;
                        transition: transform #{.8 + $i/(4 * 2)}s $out-expo #{$i/(4 * 4)}s;

                        &:after {
                            transition: opacity #{.8 + $i/(4 * 2)}s ease-out #{$i/(4 * 4)}s
                        }
                    }
                }
            }
        }
    }
}

.c-slider__flkty {

    &.is-dragging {

        .o-card {
            pointer-events: none;
        }
    }
}

.c-slider__slide {
    width: calc(3/4 * 100%);
    padding-right: var(--grid-gutter-half);
    padding-left: var(--grid-gutter-half);

    @media #{md("xs")} {
        width: 50%;
    }

    @media #{md("sm")} {
        width: calc(2/5 * 100%);
    }

    @media #{md("md")} {
        width: calc(3/8 * 100vw - #{$header-size}/2);
    }

    @media #{md("lg")} {
        width: calc(1/4 * 100vw - #{$header-size}/2);
    }
}


