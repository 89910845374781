
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                










































































































.c-btn {
    display: inline-flex;
    align-items: center;
    padding-top: .3em;
    padding-bottom: .3em;
    color: $color-dark;
    font-family: ff("serif");
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .o-btn-circle {
        --size: 1.4em;
    }

    // Sizes
    &.-sm {
        padding: 0;
        font-family: ff("sans");
        font-size: .75em;
    }

    &.-md {
        font-size: 1.1em;
    }

    &.-lg {
        @include responsive-type($min-font: 24, $max-font: 45, $min-width: 0, $max-width: 1920);
    }

    // Modifiers
    &.-hover {

        &:after {
            transform: scale(0, 1);
            transform-origin: 100% 50%;
            transition: transform .8s $out-expo;
        }

        &:hover:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }
    }

    &.-secondary {
        color: $color-grey;
    }

    @media #{md("sm")} {

        &.-sm {
            font-size: .875em;
        }

        &.-md {
            font-size: 1.25em;
        }
    }
}

.c-btn__label {

    & + .c-btn__icon {
        margin-left: .5em;
    }
}

.c-btn__icon {
    flex-shrink: 0;

    & + .c-btn__label {
        margin-left: .5em;
    }
}

