
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                








































































































.o-card {
    padding: 1em;
    background-color: $color-light;
    white-space: normal;

    &:hover {

        .o-asset__inner {
            transform: scale(1.1);
            transition: transform 5s linear;
        }

        .o-card__btn:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }
    }
}

.o-card__header {
    display: flex;
    flex-direction: column-reverse;
}

.o-card__title {
    margin-top: .25em;
    margin-bottom: .25em;
}

.o-card__data {
    display: flex;
    color: $color-dark;
}

.o-card__count,
.o-card__dates {
    flex: 1 1 0;
}

.o-card__dates {
    text-align: right;
}

.o-card__asset {
    margin-bottom: 5vmax;
    max-height: 24em;
    overflow: hidden;

    .o-asset__inner {
        transition: transform .4s ease-out;
    }
}

.o-card__btn {
    cursor: pointer;
}


