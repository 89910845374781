
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




























































































































































.l-heading {
    padding-top: $header-size;
    padding-bottom: $header-size;
}

.l-header__return {

    @media #{md("sm")} {
        // top: -$header-size;

        & + .grid {
            padding-top: 15vh;
        }
    }
}

.l-heading__inner {

    @media #{md("sm")} {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.l-heading__title {
    --anim-delay: #{$grid-layout-duration-out};

    @media #{md("sm", "max")} {
        margin-bottom: 5vh;
    }

    @media #{md("sm")} {
        order: 2;

        & + .l-heading__header .l-heading__intro {
            margin-bottom: 20vh;
        }

        & ~ .l-heading__desc {
            margin-top: 10vh;
        }
    }

    & + .l-heading__header {

        .l-heading__sub {
            --anim-delay: #{$grid-layout-duration-out + .8s};
        }

        .l-heading__intro {
            --anim-delay: #{$grid-layout-duration-out + 1s};
        }

        & + .l-heading__desc {
            --anim-delay: #{$grid-layout-duration-out + 1.2s};
        }
    }

    & + .l-heading__desc {
        --anim-delay: #{$grid-layout-duration-out + .8s};
    }
}

.l-heading__header {

    @media #{md("sm")} {
        order: 1;
    }
}

.l-heading__sub {
    --anim-delay: #{$grid-layout-duration-out};
    margin-bottom: .5em;

    & + .l-heading__intro {
        left: -.05em;
    }
}

.l-heading__intro {
    --anim-delay: #{$grid-layout-duration-out + .4s};
}

.l-heading__desc {
    --anim-delay: #{$grid-layout-duration-out};

    margin-top: 5vh;
    padding-top: .75em;
    padding-bottom: .75em;

    &:before {
        @include pseudo-el($bg: $color-beige);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &.is-visible {

        &:before {
            opacity: 1;
            transition: opacity var(--anim-duration-in) ease-in-out var(--anim-delay);
        }
    }

    .is-beige & {
        color: $color-dark;
    }

    @media #{md("xs")} {
        width: 85%;
    }

    @media #{md("sm")} {
        order: 3;
        width: 73%;
    }

    @media #{md("md")} {
        width: 54%;
    }
}

.l-heading__photo {
    --reveal-end: 1;

    position: absolute;
    will-change: transform;

    &.-cover {
        --parallax-y: 7;
        top: 0;
        right: -$header-size;
        width: calc(1/4 * 100% + #{$header-size});
    }

    &.-i1 {
        --parallax-y: -7;
        top: -5vh;
        right: -$header-size;
        width: calc(1/4 * 100% + #{$header-size});
    }

    &.-i2 {
        --parallax-y: -5;
        top: 40%;
        left: calc(1/2 * 100%);
        width: calc(1/3 * 100%);
    }

    &.-i3 {
        --parallax-y: -3;
        bottom: 0;
        left: calc(3/4 * 100%);
        width: calc(1/4 * 100% + var(--grid-gutter));
    }

    @media #{md("sm", "max")} {
        display: none !important;
    }

    @media #{md("lg")} {

        &.-i1,
        &.-cover {
            width: calc(1/4 * 100%);
        }

        &.-i2 {
            top: auto;
            bottom: 0;
        }

        &.-i3 {
            display: none;
        }
    }
}

