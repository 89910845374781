
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


































































































































































































































* {
    cursor: none !important;
}

.o-cursor {
    --cursor-color: #{$color-beige};

    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 6em;
    height: 6em;
    margin-top: calc(-6em/2);
    margin-left: calc(-6em/2);
    pointer-events: none;

    mix-blend-mode: exclusion;

    // City hover
    &.-city {
        mix-blend-mode: initial;

        .o-cursor__dot {
            background-color: $color-red;
            transform: scale(1);
            transition: transform .5s $in-out-circ, background-color .2s ease-in;
        }

        .o-cursor__circle {
            transform: scale(0);
            opacity: 0;
            transition: all .2s $in-circ;
        }
    }


    // Highlight
    &.-hover {

        .o-cursor__dot {
            background-color: transparent;
            border-width: .7em;
            //border-color: rgba($color-dark, .15);
            //border-color: var(--cursor-color);
            //transform: scale(.4);
            transform: scale(0);
            transition: transform .15s $in-circ, background-color .15s ease-in, border-width .15s ease-in;
        }

        .o-cursor__circle {
            transform: scale(.5);
            border-width: 1em;
            //opacity: 0;
            transition: all .3s $in-out-circ;
        }

        &.is-active {

            .o-cursor__circle {
                transform: scale(.7);
                transition: transform .1s ease-out;
            }
        }
    }

    // Drag
    &.-drag,
    &.-slide-x,
    &.-slide-y {

        .o-cursor__arrows {
            transform: scale(1) rotate(0);
        }

        .o-cursor__arrow {
            transition: opacity .2s ease-in;
        }

        .o-cursor__dot {
            transform: scale(.06);
        }

        .o-cursor__circle {
            transform: scale(0);
            opacity: 0;
            transition: all .15s $in-circ;
        }

        &.is-active {

            .o-cursor__arrow {
                &.-top       { transform: translate(0, -50%) }
                &.-bottom    { transform: translate(0, 50%) }
                &.-right     { transform: translate(50%, 0) }
                &.-left      { transform: translate(-50%, 0) }
            }

            .o-cursor__dot {
                transform: scale(.08);
            }
        }
    }

    // Drag
    &.-drag {

        .o-cursor__arrows {
            transition: transform .3s $in-out-circ, opacity .2s ease-in;
        }

        .o-cursor__arrow {
            opacity: 1;
            transform: none;
        }

        &.is-active {

            .o-cursor__arrows {
                transform: scale(1.1) rotate(-45deg);
            }
        }
    }

    // Slide
    &.-slide-x,
    &.-slide-y {

        .o-cursor__arrows {
            transition: none;
        }
    }

    // Slide X
    &.-slide-x {

        .o-cursor__arrows {
            transition: none;
        }

        .o-cursor__arrow {

            &.-left,
            &.-right {
                opacity: 1;
                transform: translate(0);
                transition: all .3s $in-out-circ
            }
        }

        &.is-active {

            .o-cursor__arrow {

                &.-left {
                    transform: translate(-50%, 0);
                }

                &.-right {
                    transform: translate(50%, 0);
                }
            }
        }
    }

    // Slide Y
    &.-slide-y {

        .o-cursor__arrow {

            &.-top,
            &.-bottom {
                opacity: 1;
                transform: translate(0);
                transition: all .3s $in-out-circ
            }
        }

        &.is-active {

            .o-cursor__arrow {

                &.-top {
                    transform: translate(0, -50%);
                }

                &.-bottom {
                    transform: translate(0, 50%);
                }
            }

        }
    }

    // Asset in grid canvas
    &.-canvas-item {
        mix-blend-mode: overlay;

        --cursor-color: #{$color-light};

        .o-cursor__dot {
            transform: scale(.35);
        }

        .o-cursor__circle {
            border-width: 2px;
            border-color: $color-dark;
            transform: scale(.5);
        }
    }

    // Close
    &.-close {
        mix-blend-mode: unset;

        .o-cursor__cross {
            opacity: 1;
            transform: scale(1);
            transition: all .3s $in-out-circ;
        }
    }

    &.-hide,
    &.-close {

        .o-cursor__dot {
            transform: scale(0);
            transition: all .2s $in-circ;
        }

        .o-cursor__dot,
        .o-cursor__circle {
            transform: scale(0);
            opacity: 0;
            transition: all .2s $in-circ;
        }
    }
}

.o-cursor__dot,
.o-cursor__circle,
.o-cursor__scroll {
    position: absolute;
    display: block;
    border-radius: 50%;
}

.o-cursor__dot {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cursor-color);
    border: 0 solid transparent;
    transform: scale(.08);
    transition: transform .3s $out-circ, opacity .2s ease-out;
    will-change: transform;
}

.o-cursor__circle,
.o-cursor__scroll {
    top: calc((100% - 30%)/2);
    left: calc((100% - 30%)/2);
    width: 30%;
    height: 30%;
}

.o-cursor__circle {
    border: 1px solid var(--cursor-color);
    transition: all .3s $out-circ .1s;
}

.o-cursor__arrows {
    position: absolute;
    top: calc(50% - 35%/2);
    left: calc(50% - 35%/2);
    display: block;
    width: 35%;
    height: 35%;
    color: var(--cursor-color);
    transform: scale(.5) rotate(-90deg);
    transition: all .2s $in-circ;
    will-change: transform;
}

.o-cursor__cross {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $color-light;
    opacity: 0;
    transform: scale(0);
    transition: all .2s $in-circ;
}

.o-cursor__arrow {
    position: absolute;
    opacity: 0;
    transition: inherit;

    &.-top,
    &.-bottom {
        left: calc(50% - .5625em/2);
    }

    &.-left,
    &.-right {
        top: calc(50% - .5625em/2);
    }

    &.-top {
        top: 0;
    }

    &.-bottom {
        bottom: 0;
    }

    &.-right {
        right: 0;
        transform: translate(-200%);
    }

    &.-left {
        left: 0;
        transform: translate(200%);
    }
}

.o-cursor__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $color-light !important;
}

.o-cursor__scroll {
    display: block;
    border: .25em solid $color-beige;
    border-radius: 50%;
    clip-path: var(--clip-path);
    opacity: 0;
    transform: rotate(-45deg) scale(.5);
    transition: opacity .2s ease-in, transform .2s $in-circ;

    @supports (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
        clip-path: var(--clip-path);
    }

    @supports not (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
        display: none;
    }

    &.is-visible {
        opacity: .7;
        transform: rotate(-45deg) scale(1);
        transition: opacity .2s ease-out, transform .2s $out-circ;
    }
}

