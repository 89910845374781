
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































































































































































































































































































// .p-order {}

.p-order__title {
    justify-content: center;

    @media #{md("sm", "max")} {
        margin-top: 10vh;
    }

    @media #{md("sm")} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.p-order__asset {
    width: 75vw;
    padding-top: 10vh;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5vh;
    @media #{md("sm")} {
        width: 35vw;
    }
}


/*----------  Success  ----------*/

.o-success {
    margin-top: 5vh;
    margin-bottom: 5vh;
    color: $color-dark;
    text-align: center;
}

.o-success__title {
    margin-bottom: .75em;
}

.o-success__desc {}


