
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

































































































































































































































































































































































































.p-search {
    min-height: vh(100);

    .c-form {
        margin-bottom: 7vh;
    }

    .c-form__el {
        opacity: 0;
        transform: translate(0, 1em);
        transition: 0s 1s;

        .o-input.-text .o-input__label {
            width: 100%;
        }

        &.-submit .c-btn {
            transition: color .4s ease-out .2s;

            .o-btn-circle {
                color: inherit;
            }
        }
    }

    &.is-loaded {

        .c-form__el {
            opacity: 1;
            transform: translate(0);

            @for $i from 1 through 5 {

                &:nth-child(#{$i}) {
                    $i: $i - 1;
                    transition: opacity .4s ease-out #{1 + $i/12}s, transform .6s $out-circ #{1 + $i/12}s;
                }
            }
        }
    }

    &.is-loading {

        .c-form__el.-submit .c-btn {
            color: $color-grey;
            pointer-events: none;
        }
    }
}

.p-search__title {
    //--anim-delay: #{$grid-layout-duration-out};

    justify-content: center;

    @media #{md("sm", "max")} {
        margin-top: 1em;
    }
}

.p-search__return {
    --transition-delay-visible: .4s;

    @media #{md("sm", 'max')} {
        left: calc(50% - var(--size)/2);
        margin-top: 1em;
    }

    @media #{md("sm")} {
        z-index: 1;
        position: fixed;
        top: var(--grid-gutter);
        right: var(--grid-gutter);
    }
}

.p-search__count {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-dark;
    text-align: center;

    .o-btn-circle {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .o-btn-circle__label {
        margin: 0;
    }
}

.p-search__loader {
    position: absolute;
    top: 20%;
    left: 20%;
    width: calc(100% - 20% * 2);
    height: calc(100% - 20% * 2);
    color: inherit;

    path {

        &[fill] {
            fill: currentColor !important;
        }

        &[stroke] {
            stroke: currentColor !important;
        }
    }
}


/*===================================
=            Placeholder            =
===================================*/

.o-placeholder {
    display: block;
    width: 100%;
    overflow: hidden;
}

.o-placeholder__inner {
    display: block;
    overflow: hidden;
    transition: transform .2s ease-out;

    .is-filled & {
        transform: translate(0, 100%);
    }
}

.o-placeholder__item {

    position: absolute;
    top: 0;
    left: 6.4em;
    display: block;
    white-space: nowrap;
    transform: translate(0, -100%);
    animation: anim-word-loop 10s infinite;

    @for $i from 2 through 4 {
        &:nth-child(#{$i}) {
            animation-delay: #{($i - 1)/4 * 10}s
        }
    }
}

@keyframes anim-word-loop {
    0% {
        transform: translate(0, -100%);
    }
    5% {
        transform: translate(0);
    }
    22.5% {
        transform: translate(0);
    }
    27.5% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 100%);
    }
}


