
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                













































































































.o-btn-circle {
    --size: 3em;

    --color-bg: transparent;
    --color-text: #{$color-dark};

    --transition-delay-visible: 0s;
    --transition-delay-hidden: 0s;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    color: var(--color-text);
    border-radius: 50%;
    cursor: pointer;
    transition: transform .6s $out-back var(--transition-delay-visible);

    &.is-hidden {
        transform: scale(0);
        transition: transform .6s $in-back var(--transition-delay-hidden);
    }

    &.-blue {
        --color-bg: #{$color-blue};
        --color-text: #{$color-light};
    }

    &.-white {
        --color-bg: #{$color-light};
    }

    &.-border {

        &:before {
            border: 1px solid var(--color-text);
        }
    }

    &:before {
        @include pseudo-el($bg: var(--color-bg));
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        transition: transform .5s $in-out-quad;
    }

    &:hover,
    &.is-hovering {

        &:before {
            transform: scale(1.2);
            transition: transform .4s $in-out-quad;
        }

        .o-btn-circle__icon {
            transform: scale(.9);
            transition: transform .4s $in-out-quad;
        }

        .o-btn-circle__label {

            .o-at__w-inner {
                animation: at-word-in-down .2s ease-out .2s forwards;
            }

            & + .o-btn-circle__icon {
                transform: scale(0);
                transition: transform .2s $in-circ;
            }
        }
    }

    // Sizes
    &.-sm {
        --size: 2em;
    }

    &.-lg {
        --size: 6em;

        &:hover,
        &.is-hovering {

            &:before {
                transform: scale(1.1);
            }

            // .o-btn-circle__icon {
            //     transform: scale(.95);
            // }
        }
    }

    // Display
    &.-border {

        &:before {
            border: 1px solid currentColor;
        }
    }

    @media #{md("sm", "max")} {
            font-size: .75rem;
    }
}

.o-btn-circle__icon {
    transition: transform .5s $in-out-quad;

    .svg-arrow-left,
    .svg-arrow-right {
        width: calc(var(--size) - .7em);
        height: calc((var(--size) - .7em) * 9/16);
    }
}

.o-btn-circle__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit !important;

    .o-at__w-inner {
        animation: at-word-out-up .2s ease-in forwards;
    }

    & + .o-btn-circle__icon {
        transition: transform .2s $out-circ .3s;
    }
}

