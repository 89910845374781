
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






































































































// .p-directory {}

.p-directory__regions {
    @include reset-list;
    margin-top: 5vh;
    margin-bottom: 5vh;

    li {
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;
        padding-right: var(--grid-gutter-half);
        padding-left: var(--grid-gutter-half);

        &.js-reveal {
            opacity: 0;

            &.is-visible {
                opacity: 1;
                transition: opacity .2s ease-out;
            }
        }
    }

    a {
        display: inline-block;
        line-height: 1.2;
    }

    @media #{md("sm")} {
        @include clearfix;

        li {
            width: 50%;

            &:nth-child(2n+1) {
                float: left;
            }

            &:nth-child(2n) {
                float: right;
            }
        }
    }
}

