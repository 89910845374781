
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


































































































































.o-grid-layout {
    position: absolute;
    pointer-events: none;

    --transition-duration: #{$grid-layout-duration-in};
}

.o-grid-layout__items {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.is-black {
        --color-bg: #{$color-dark};
        --color-border: #{$color-grey};
    }

    &.is-blue {
        --color-bg: #{$color-blue};
        --color-border: #{$color-blue-bright};
    }

    &.is-white {
        --color-bg: #{$color-light};
        --color-border: #{$color-peach};
    }

    &.is-beige {
        --color-bg: #{$color-beige};
        --color-border: #{$color-grey};
    }

    &.-overlay {
        --origin-y: 100%;
        z-index: 20;

        .o-grid-layout__item {
            transform: scale(1, 0) translateZ(0);
            transform-origin: 50% var(--origin-y);
            will-change: transform;
        }

        &.is-visible {

            .o-grid-layout__item {
                transform: scale(1, 1) translateZ(0);
            }
        }

        &.in-motion {

            .o-grid-layout__item {

                @for $i from 1 through 4 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        transition: transform #{$grid-layout-duration-in - $grid-layout-duration-in * $i/8} $in-out-circ #{$grid-layout-duration-in * $i/8};

                        html.is-safari &, html.is-ios & {
                            transition: transform #{.8s - .8s * $i/8} $in-out-circ #{$grid-layout-duration-in * $i/8};
                        }
                    }
                }
            }
        }
    }

    @media #{md("sm")} {

        &.-overlay {

            &.in-motion {

                .o-grid-layout__item {

                    @for $i from 1 through 6 {

                        &:nth-child(#{$i}) {
                            $i: $i - 1;
                            transition: transform #{$grid-layout-duration-in - $grid-layout-duration-in * $i/12} $out-sine #{$grid-layout-duration-in * $i/12};

                            html.is-safari &, html.is-ios & {
                                transition: transform #{.8s - .8s * $i/12} $out-sine #{.5s * $i/12};
                            }
                        }
                    }
                }
            }
        }
    }
}

.o-grid-layout__item {
    --col-width: calc((100% - #{$grid-gutter--mobile} * 2)/2);

    position: absolute;
    top: 0;
    display: block;
    flex: 0 0 auto;
    height: 100%;
    width: var(--col-width);
    background-color: var(--color-bg);

    // Add left border
    &:not(:first-child):before {
        @include pseudo-el($width: 1px, $height: 100%, $bg: var(--color-border));
        position: absolute;
        bottom: 0;
        left: 0;
    }

    // Position
    &:nth-child(1) { left: 0 }

    @for $i from 2 through 3 {
        &:nth-child(#{$i}) {
            $i: $i - 2;
            left: calc(var(--grid-gutter) + #{$i} * var(--col-width));
        }
    }


    @media #{md("sm", "max")} {

        // Change border height
        &:not(:first-child):before {
            height: calc(100% - #{$header-size});
        }

        &:nth-child(1),
        &:nth-child(4) {
            --col-width: var(--grid-gutter);
        }

        &:nth-child(4) { right: 0 }

        &:nth-child(5),
        &:nth-child(6) {
            display: none;
        }
    }

    @media #{md("sm")} {
        --col-width: calc((100% - #{$header-size} * 2)/4);

        &:nth-child(1),
        &:nth-child(6) {
            --col-width: #{$header-size};
        }

        // Correct 1px bug
        &:nth-child(3) {
            width: calc(var(--col-width) + 1px);
        }

        // Position
        &:nth-child(6) { right: 0 }

        @for $i from 2 through 5 {
            &:nth-child(#{$i}) {
                $i: $i - 2;
                left: calc(#{$header-size} + #{$i} * var(--col-width));
            }
        }
    }
}

