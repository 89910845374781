
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
























































































































































































































// .p-about {}

.p-about__heading {

    @media #{md("sm")} {
        min-height: 100vh
    }
}

.p-about__content {
    margin-top: 10vh;

    > div {
        padding-top: .75em;
        padding-bottom: .75em;
        background-color: $color-beige;
        color: $color-dark;
    }
}


/*----------  Data  ----------*/

.o-stats {
    margin-right: calc(-1 * var(--grid-gutter-half));
    margin-left: calc(-1 * var(--grid-gutter-half));
}

.o-stats__photos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.o-stats__photo {
    position: absolute;
    will-change: transform;

    html.is-ios &, html.is-safari {
        transition: transform .75s ease-out;
    }

    &.-i1 {
        --parallax-y: -10;

        top: 0;
        left: 50%;
        width: calc(1/2 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    &.-i2 {
        --parallax-y: -7;

        top: 25%;
        left: 0;
        width: calc(2/5 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    &.-i3 {
        --parallax-y: 2;

        bottom: 25%;
        right: 0;
        width: calc(2/5 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    &.-i4 {
        --parallax-y: -7;

        bottom: -10vh;
        right: 50%;
        width: calc(1/2 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    @media #{md("xs")} {

        &.-i1 { width: calc(1/3 * 100%) }
        &.-i2 { width: calc(2/5 * 100%) }
        &.-i3 { width: calc(1/4 * 100%) }
        &.-i4 { width: calc(2/5 * 100%) }
    }

    @media #{md("sm")} {

        &.-i1 {
            left: calc(1/3 * 100%);
            width: calc(1/3 * 100%);
        }

        &.-i2 {
            right: -$header-size;
            left: auto;
            width: calc(1/6 * 100% + #{$header-size});
        }

        &.-i3 {
            left: 0;
        }

        &.-i4 {
            right: auto;
            left: 50%;
            width: calc(2/5 * 100%);
        }
    }

    @media #{md("lg")} {

        &.-i1 {
            left: calc(3/8 * 100%);
            width: calc(1/4 * 100%);
        }

        &.-i4 {
            width: calc(1/3 * 100%);
        }
    }
}

.o-stats__inner {
    left: calc(-1 * var(--grid-gutter));
    width: 100vw;
    display: block;
    overflow: hidden;

    @media #{md("sm")} {
        left: 0;
        width: calc(100vw - #{$header-size});
    }
}

.o-stats__item {
    display: flex;
    margin-top: 15vh;
    margin-bottom: 15vh;
    will-change: transform;

    html.is-ios &, html.is-safari {
        transition: transform .75s ease-out;
    }

    &.-i1 {
        --parallax-x: -20;
        --parallax-y: 5;

        @media #{md("xs", "max")} {
            --parallax-x: -10;
            --parallax-y: 0;
        }
    }

    &.-i2 {
        --parallax-x: -30;
        --parallax-y: 0;

        @media #{md("xs", "max")} {
            --parallax-x: 15;
        }
    }

    &.-i3 {
        --parallax-x: -40;
        --parallax-y: -5;

        @media #{md("xs", "max")} {
            --parallax-x: -20;
            --parallax-y: 0;
        }
    }
}

.o-stats__text {
    white-space: nowrap;

    &:nth-child(n+2) {
        margin-left: 1em;
    }
}



/*----------  Block  ----------*/

.o-block {
    --reveal-end: 1;
    --padding: 1.5em;

    margin-top: 10vh;
    color: $color-dark;
    background-color: $color-light;

    &.js-reveal {

        .-chars {
            @include at-chars-out;
        }

        .-html {
            @include at-html-out;
        }

        &.js-visible {

            .-chars {
                @include at-chars-in;
            }

            .-html {
                --anim-delay: .2s;
                @include at-html-in;
            }
        }
    }

    @media #{md("sm")} {
        --padding: 2.5em;
    }

    @media #{md("lg")} {
        --padding: 3em;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.o-block__content {
    padding-top: var(--padding);
    padding-right: var(--padding);
    padding-left: var(--padding);

    @media #{md("lg")} {
        width: 50%;
    }
}

.o-block__title {
    margin-bottom: .5em;
}

.o-block__btn {
    margin-top: 1em;
}

.o-block__fig {
    width: 50%;
    padding-top: .5em;

    img {
        @include img;
    }

    @media #{md("lg", "max")} {
        display: none;
    }

    @media #{md("lg")} {
        padding-right: var(--padding);
        padding-left: var(--padding);
    }
}

.o-block__bottom {
    display: flex;
    align-items: center;
    padding-top: var(--padding);

    > * {
        flex: 0 0 auto;
    }

    .o-icon {
        padding: 1em;
        color: $color-light;
        background-color: $color-red;
        border-top-right-radius: .4em;
        border-top-left-radius: .4em;
    }

    @media #{md("xs", "max")} {
        flex-direction: column;
    }
}

.o-block__bottom-title {
    margin-right: var(--grid-gutter);

    @media #{md("xs", "max")} {
        margin-top: 1em;
    }
    @media #{md("md")} {
        margin-right: var(--grid-gutter-half);
    }
}

.o-block__bottom-link {
    display: block;

    @media #{md("xs", "max")} {
        margin-top: 1em;
    }
}

