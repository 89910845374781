
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































































































































































































































































































































































.p-home {

    @media #{md("sm", "max")} {

        &.l-wrapper {
            overflow-y: hidden;
        }
    }
}

.p-home__container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100% - #{$header-size});

    &:before {
        @include pseudo-el($width: 100vw, $bg: $color-grey);
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
        opacity: 0;
        transition: opacity .6s ease-out .1s;
    }

    &.is-dragging {

        &:before {
            opacity: .8;
        }

        .p-home__title {
            mix-blend-mode: overlay;
            //transform: translate(0, -50%) scale(.8);

            .o-at__c {
                --anim-duration-in: .6s;
                --anim-delay: .1s;
                transform: translate(calc(-.08em * (var(--anim-chars)/2 - var(--anim-offset))), 0) scale(.9);

                .is-firefox & {
                    transform: scale(.9);
                }
            }
        }

        .p-home__intro {
            --anim-duration-out: .4s;
            --anim-delay: .3s;

            .o-at__w-inner {
                transform: translate(0);
                animation:
                    at-word-out-down
                    calc(var(--anim-duration-out) - var(--anim-duration-out) * (var(--anim-offset) / var(--anim-words)) / 4)
                    ease-in
                    calc(var(--anim-duration-out) * ((var(--anim-words) - var(--anim-offset)) / var(--anim-words)) / 4)
                    forwards;
            }
        }
    }

    &.is-hovering {

        &:before {
            opacity: .8;
        }
    }

    &.has-tutorial {
        z-index: 200;

        .p-home__title {
            z-index: 201;
            color: $color-light;
        }
    }

    &.has-title {

        .o-grid-canvas__item {
            pointer-events: none;

            // Hover bug fix safari
            .is-safari & {
                pointer-events: initial;
            }
        }
    }

    @media #{md("sm", "max")} {
        padding-right: $grid-gutter--mobile;
        padding-left: $grid-gutter--mobile;
    }

    @media #{md("sm")} {
        height: 100%;
        width: calc(100% - #{$header-size});

        &:before {
            left: 0;
            width: calc(100% + #{$header-size});
        }
    }
}

.p-home__intro,
.p-home__title {
    z-index: 1;
    position: absolute;
    pointer-events: none;
}

.p-home__title {
    top: 50%;
    left: 0;
    width: 100%;
    justify-content: center;
    transform: translate(0, -50%);
    transition: color .4s ease-out;

    .o-at__w {
        width: 100%;
        justify-content: center;
    }

    &.-photo {
        color: $color-light;

        .o-at__w {
            width: auto;
        }

        &.is-visible {
            --hover-extra-delay: .4s;

            .o-at__w:after {
                transform: scale(1);
                transform-origin: 0 50%;
            }
        }
    }

    @media #{md("sm")} {
        right: $header-size;
        left: $header-size;
        width: auto;
        margin-right: 0;
        margin-left: 0;
    }
}

.p-home__intro {
    --anim-delay: .8s;

    bottom: 1.25rem;
    left: calc(1/24 * 100%);
    width: calc(3/4 * 100%);
    transform-origin: 0 100%;

    @media #{md("xs")} {
        width: 16em;
    }
}



/*===================================
=            Grid canvas            =
===================================*/



.o-grid-canvas {
    position: fixed;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &.-repeat-x {

        .o-grid-canvas__box {

            @each $col in (1, 2, 7, 8, 9, 10, 15, 16, 20, 21) {
                &:nth-child(#{$col}) {
                    left: var(--width);
                }
            }
        }
    }

    &.-repeat-y {

        .o-grid-canvas__box {

            &:nth-child(-n+14) {
                top: var(--height);
            }
        }
    }

    @media #{md("sm", "max")} {
        width: 100%;
        height: calc(100% - #{$header-size});
    }

    @media #{md("sm")} {
        width: calc(100% - #{$header-size});
        height: 100%;
    }
}

.o-grid-canvas__inner {
    @include reset-list;

    width: calc(var(--photos-x) * 100%);
    height: calc(var(--photos-y) * 100%);

    --columns-x: 3;
    --columns-y: 2;
    --width: calc(var(--columns-x) * (100vmax - #{$header-size}));;
    --height: calc(var(--columns-y) * (100vmax - #{$header-size}));;
    width: var(--width);
    height: var(--height);
    display: grid;
    grid-template-columns: repeat(calc(var(--columns-x) * 3), 1fr);
    grid-template-rows: repeat(calc(var(--columns-y) * 3), 1fr);

    // Grid layout 14 items + 12 items => 26 items
    /*
        —— xx - | - ——
        |- xx | | xx |
        |—— - | - xx |
        xx - —— xx ———
        xx xx | xx - |
        —— xx | - —— |
    */

    grid-template-areas:
        "w1  w1  b1  b1  s1  h1  s2  w2  w2"
        "h2  s3  b1  b1  h3  h1  b2  b2  h4"
        "h2  w3  w3  s4  h3  s5  b2  b2  h4"
        "b3  b3  s6  w4  w4  b4  b4  w5  w5"
        "b3  b3  b5  b5  h5  b4  b4  s8  h6"
        "w6  w6  b5  b5  h5  s7  w7  w7  h6"
}

.o-grid-canvas__box {
    --x: 0;
    --y: 0;

    width: 100%;
    height: 0;
    padding-top: 100%;

    // Small
    $i: 1;
    @each $col in (3, 5, 8, 10, 12, 16, 23, 25) {
        &:nth-child(#{$col}) {
            grid-area: s#{$i};
        }
        $i: $i+1;
    }

    // Large
    $i: 1;
    @each $col in (1, 6, 9, 17, 19, 20, 24) {
        &:nth-child(#{$col}) {
            grid-area: w#{$i};
            padding-top: 50%;

            .o-asset {
                width: calc(100% * (2 - var(--ratio)));

                &.-portrait {
                    width: calc(100% * var(--ratio)/2);
                }
            }
        }
        $i: $i+1;
    }

    // Long
    $i: 1;
    @each $col in (4, 7, 11, 14, 22, 26) {
        &:nth-child(#{$col}) {
            grid-area: h#{$i};
            padding-top: 200%;
        }
        $i: $i+1;
    }

    // Big
    $i: 1;
    @each $col in (2, 13, 15, 18, 21) {
        &:nth-child(#{$col}) {
            grid-area: b#{$i};
        }
        $i: $i+1;
    }
}

.o-grid-canvas__item {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5vmax;

    .o-asset {
        --reveal-infinite: 1;

        z-index: 0;
        width: 100%;
        height: auto;
        transform: scale(1);
        transition: transform .6s $in-out-circ .1s, z-index 0s .6s;

        &:after {
            @include pseudo-el($width: 100%, $bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity .6s ease-out;
        }

        &.-portrait {
            width: calc(100% * var(--ratio));
        }

        .is-hovering &:after {
            opacity: .8;
        }

        .o-asset__inner {
            overflow: hidden;
        }

        .o-asset__img {
            transform-origin: 50% 100%;
            transition: transform .6s $in-out-circ .1s;
        }

        &:hover,
        .is-dragging & {
            z-index: 10;
            transition: transform .6s $in-out-circ .1s, z-index 0s .1s;

            .o-asset__img {
                transform: scale(1.1);
            }

            &:after {
                opacity: 0;
            }
        }

        .is-dragging & {
            transform: scale(.8);
        }

        &:hover {
            transform: scale(1.1);
            pointer-events: initial;
        }
    }
}

.o-grid-canvas__proxy {
    display: none;
}


/*----------  Tutorial  ----------*/

.o-tutorial {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    color: $color-light;

    .o-grid-layout {
        pointer-events: initial;
        opacity: .9;
    }

    .o-grid-layout__items.-bg {
        display: none;
    }

    @media #{md("sm", "max")} {
        //height: calc(50% - #{$header-size});
        //padding-top: 0;
    }
}

.o-tutorial__close {
    --color-text: #{$color-blue};
    --transition-delay-visible: .2s;

    position: absolute;
    top: 1.25rem;
    left: calc(50% - var(--size)/2);
}

.o-tutorial__inner {
    //pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50%;
    pointer-events: none;
}

.o-tutorial__lottie {
    width: auto;
    max-width: 20em;
    height: auto;
    max-height: 70%;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0;
    transform: scale(.85);
    transition: opacity .3s ease-in 0s, transform .3s $in-circ 0s;

    &.is-visible {
        opacity: 1;
        transform: scale(1);
        transition: opacity .4s ease-out .4s, transform .6s $out-circ .4s;
    }
}

.o-tutorial__text {
    justify-content: center;
    color: $color-light;
}

