
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                































































































































































































































































































































































































































































































































































.o-asset {
    --reveal-end: 1;

    display: block;

    &.is-loaded {

        .o-asset__img {
            opacity: 1;
            transition: opacity .2s ease-out, transform .8s $out-expo;
        }

        &:hover {

            .o-hover {
                transform: scale(1);

                @supports (clip-path: polygon(0 0, 0 0)) {
                    clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
                }

                @supports not (clip-path: polygon(0 0, 0 0)) {
                    opacity: 1;
                }

                & + .o-asset__img {
                    transform: scale(.75);
                    transition: transform .8s $out-expo;
                }
            }
        }
    }

    &.is-hidden,
    &.js-reveal {
        --reveal-duration: 1s;
        --reveal-delay: 0s;

        .o-asset__inner {
            background-color: $color-grey;
            overflow: hidden;
            transform: translate(0, 2em);

            @supports (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            }

            @supports not (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                opacity: 0;
            }
        }

        .o-asset__img {
            transform: scale(1.2);
            transform-origin: 50% 0;
        }

        &.is-visible.is-loaded {

            .o-asset__inner {
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                transform: translate(0);
                transition:
                    clip-path var(--reveal-duration) $in-out-circ var(--reveal-delay),
                    transform var(--reveal-duration) $in-out-circ var(--reveal-delay);

                @supports (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    transition:
                        clip-path var(--reveal-duration) $in-out-circ var(--reveal-delay),
                        transform var(--reveal-duration) $in-out-circ var(--reveal-delay);
                }

                @supports not (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                    opacity: 1;
                    transition:
                        opacity var(--reveal-duration) $in-circ var(--reveal-delay),
                        transform var(--reveal-duration) $in-out-circ var(--reveal-delay);
                }
            }

            .o-asset__img {
                transform: scale(1);
                transition: transform var(--reveal-duration) $in-out-circ var(--reveal-delay);
            }
        }
    }

    &.is-hidden {

        .o-asset__inner {
            transition:
                clip-path calc(var(--reveal-duration)/3) $in-circ calc(var(--reveal-delay)/3),
                transform calc(var(--reveal-duration)/3) $in-circ calc(var(--reveal-delay)/3);
        }

        .o-asset__img {
            transition: transform calc(var(--reveal-duration)/3) $in-circ calc(var(--reveal-delay)/3);
        }
    }

    &.is-zooming {

        .o-asset__btn.-zoom-in {
            transform: scale(0);
            transition: transform .2s $in-circ 0s;
        }

        .o-zoom {
            left: 0;
            pointer-events: initial;
            transition: left 0s .0s;
        }

        .o-zoom__btn {
            transform: scale(1);
            transition: transform .4s $out-circ .6s;
        }

        .o-zoom__watermark {
            opacity: .15;
            transition: opacity .2s ease-out .6s;
        }
    }

    &.-contain {

        &.-portrait {
            display: flex;

            .o-asset__inner {
                width: calc(var(--ratio) * 100%);
                padding-top: 100%;
            }
        }
    }

    &.-grayscale {
        filter: grayscale(1);
    }
}

.o-asset__inner {
    width: 100%;
    height: 0;
    padding-top: calc(1/var(--ratio) * 100%);
    overflow: hidden;
}

.o-asset__img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    // height: auto;
    overflow: hidden;

    opacity: 0;

    img {
        @include img;
    }
}


.o-asset__btn {
    position: absolute;
    top: 1em;
    right: 1em;

    &.-zoom-in {
        transition: transform .4s $out-circ .6s;
    }
}


/*=============================
=            Hover            =
=============================*/


.o-hover {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: .75em;
    color: $color-dark;
    background-color: $color-light;
    transform: scale(1.1);

    .l-wrapper.is-white & {
        background-color: $color-beige;
    }

    @supports (clip-path: polygon(0 0, 0 0)) {
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
        transition: clip-path .8s $out-expo, transform .8s $out-expo;
    }

    @supports not (clip-path: polygon(0 0, 0 0)) {
        opacity: 0;
        transition: opacity .2s ease-out, transform .8s $out-expo;
    }
}

.o-hover__tags {
    margin-bottom: auto !important;
}

.o-hover__title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



/*==================================
=            Zoom image            =
==================================*/


.o-zoom {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 200%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    transition: left 0s .6s;
}

.o-zoom__picture {
    position: static;
}

.o-zoom__img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform-origin: 0 0;
}

.o-zoom__btn {
    transform: scale(0);
    transition: transform .2s $in-circ 0s;
}

.o-zoom__watermark {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .2s ease-in 0s;
}

