/*=================================
=            Formulars            =
=================================*/


.c-form {
    margin-bottom: 15vh;
}

.c-form__error {
    color: red;
    padding: 0 var(--grid-gutter);
}
.c-form__el {
    margin-top: 2em;

    &.-submit,
    &.-button {
        display: flex;
        justify-content: center;
    }

    @media #{md("xs")} {

        &.-submit {

            .c-btn {
                justify-content: space-between;
                width: 50%;
                max-width: 18rem;
            }
        }

    }
}
