/*================================================
=            Positions & dispositions            =
================================================*/


@mixin clearfix {

    &:after {
        content: "";
        clear: both;
        display: block;
    }
}



/*==================================
=            Typography            =
==================================*/


@mixin fontfaces($webfonts, $dir) {

    @each $webfont in $webfonts {


        @font-face {
            font-family: nth($webfont, 1);
            src: url("#{$dir}#{nth($webfont, 2)}.woff2") format("woff2"),
                 url("#{$dir}#{nth($webfont, 2)}.woff") format("woff");
            font-weight: #{nth($webfont, 3)};
            font-style: #{nth($webfont, 4)};
            font-display: swap;
        }
    }
}

@mixin reset-list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

@mixin ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: antialiased;
}

// Nicely hyphenate long words
// cf. https://justmarkup.com/log/2015/07/31/dealing-with-long-words-in-css/
@mixin hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}


/**
 * Responsive typography
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width: 0            The breakpoint minimum to activate the responsive typography
 * @param  {integer} $max-width: 2560         The breakpoint maximum to activate th responsive typography
 * @param  {integer} $min-font:  12           The minimum font-size for the element
 * @param  {integer} $max-font:  16           The maximum font-size for the element
 * @return {void}
 */
@mixin responsive-type(
    $min-font: 12,
    $max-font: 16,
    $min-width: 0,
    $max-width: 2560) {

    // Set min size
    font-size: $min-font * 1px;

    // Adjust size between `$min-width` et `$max-width`
    @media (min-width: #{$min_width}px) and (max-width: #{$max_width}px){
        font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));
    }

    // Set max size
    @media (min-width: #{$max_width}px){
        font-size: #{$max_font}px;
    }
}




/*=======================================
=            Pseudo elements            =
=======================================*/


@mixin pseudo-el($content: "", $display: block, $width: 100%, $height: 100%, $bg: none) {
  content: $content;
  display: $display;
  width: $width;
  height: $height;

  @if type-of($bg) == color {
     background-color: $bg;
  } @else {
     background: $bg;
  }
}


/*=============================
=            Image            =
=============================*/


@mixin img($width: 100%) {
    display: block;
    width: $width;
    height: auto;
}




/*=================================
=            Triangles            =
=================================*/

@mixin triangle($direction, $triangle-width, $triangle-height, $triangle-color) {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;

    @if $direction == up {
        border-color: transparent transparent $triangle-color;
        border-width: 0 $triangle-width / 2 $triangle-height;
    } @else if $direction == right {
        border-color: transparent transparent transparent $triangle-color;
        border-width: $triangle-height / 2 0 $triangle-height / 2 $triangle-width;
    } @else if $direction == down {
        border-color: $triangle-color transparent transparent;
        border-width: $triangle-height $triangle-width / 2 0;
    } @else if $direction == left {
        border-color: transparent $triangle-color transparent transparent;
        border-width: $triangle-height / 2 $triangle-width $triangle-height / 2 0;
    } @else {
        @error "The direction `#{$direction}` is not allowed, choose between up, right, down, left. Property omitted";
    }
}





/*======================================
=            SVG Responsive            =
======================================*/


/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/

@mixin padding-hack($svg-width, $svg-height, $container-width) {
    width: $container-width;
    height: 0;
    padding-top: strip-units(($svg-height / $svg-width) * $container-width) * 1%;
}





/*=================================
=            Gradients            =
=================================*/


/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction) == false {
        $color-stops: ($direction, $color-stops);
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: linear-gradient($direction, $color-stops);
}




/*======================================
=            Viewport width            =
======================================*/


@mixin center-viewport($width: 100vw) {
    left: calc(50% - #{$width});
    width: $width;
}


/*========================================
=            Grid breakpoints            =
=        For property dependecies        =
========================================*/


@mixin grid-gutters($property, $ratio: 1) {

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md(#{$key})} {
            #{$property}: $grid-gutter * $ratio;
        }
    }
}



/*========================================
=            Responsive class            =
========================================*/

@mixin responsive-class($max-width: true) {
    @content;

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {

            &\@#{$key} {
                @content;
            }
        }

        @if $max-width == true {
            @media #{md($key, "max")} {

                &\@#{$key}-max {
                    @content;
                }
            }
        }
    }
}



/*======================================
=            Hide scrollbar            =
======================================*/


@mixin hide-scrollbar {

    // Hide scrollbar
    scrollbar-width: none;
    scrollbar-height: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
    }
}



/* ==========================================================================
   Kinetoscope
   ========================================================================== */

@mixin kinetoscope-bg($frames, $direction, $animation-duration, $animation-iteration, $animation-ease: linear) {

    @if not index(x y, $direction) {
        @error "Direction must be either `x` or `y`."
    }

    @if $direction == x {
        background-position: 0 50%;
        background-size: auto 100%;
    }
    @else {
        background-position: 50% 0;
        background-size: 100% auto;
    }

    animation: kinetoscope-play $animation-duration * 0.7 $animation-iteration $animation-ease forwards;

    @keyframes kinetoscope-play {
        @for $i from 1 through $frames {
            #{($i - 1) * 100% / $frames}  {
                background-position-#{$direction}: #{($i - 1) / ($frames - 1) * 100%};
            }

            @if $i != $frames {
                #{($i * 100% / $frames) - 0.001%} {
                    background-position-#{$direction}: #{($i - 1) / ($frames - 1) * 100%};
                }
            }
            @else {
                100% {
                    background-position-#{$direction}: 100%;
                }
            }
        }
    }

    @keyframes kinetoscope-out {
        @for $i from 1 through $frames {
            #{($i - 1) * 100% / $frames} {
                background-position-#{$direction}: #{100% - ($i - 1) / ($frames - 1) * 100%};
            }
            @if $i != $frames {
                #{($i * 100% / $frames) - 0.001%} {
                    background-position-#{$direction}: #{100% - ($i - 1) / ($frames - 1) * 100%};
                }
            }
            @else {
                100% {
                    background-position-#{$direction}: 0%;
                }
            }
        }
    }
}



/*=============================================
=            Anim text transitions            =
=============================================*/


@mixin at-chars-in {

    .o-at__c {
        background-position: 50% 100%;
        transform: translate(0) rotateX(0);

        transition:
            transform
                calc(var(--anim-duration-in) - var(--anim-duration-in) * (var(--anim-offset) / var(--anim-chars)) / 4)
                ease-in-out
                calc(var(--anim-duration-in) * (var(--anim-offset) / var(--anim-chars)) / 2 + var(--anim-delay)),
            background-position
                calc(var(--anim-duration-in))
                ease-in
                calc(var(--anim-duration-in) * (var(--anim-offset) / var(--anim-chars)) / 2 + var(--anim-delay));
    }
}

@mixin at-chars-out {

    .o-at__c {
        background-position: 50% 0;
        transform: translate(0, .5em) rotateX(-90deg);

        transition:
            transform
                calc(var(--anim-duration-out) - var(--anim-duration-out) * (var(--anim-offset) / var(--anim-chars)) / 4)
                ease-in-out
                calc(var(--anim-duration-out) * (var(--anim-offset) / var(--anim-chars)) / 2 + var(--anim-delay)),
            background-position
                calc(var(--anim-duration-out))
                ease-in
                calc(var(--anim-duration-out) * (var(--anim-offset) / var(--anim-chars)) / 2 + var(--anim-delay));
    }
}


@mixin at-html-out {

    .o-at__html > * {
        opacity: 0;
        transform: translate(0, 2em);

        transition:
            transform
                var(--anim-duration-out)
                ease-in-out
                var(--anim-delay),
            opacity
                var(--anim-duration-out)
                ease-in-out
                var(--anim-delay);
    }
}


@mixin at-html-in {

    .o-at__html > * {
        opacity: 1;
        transform: translate(0);

        @for $i from 1 through 5 {

            &:nth-child(#{$i}) {
                $i: $i - 1;

                transition:
                    transform
                        calc(var(--anim-duration-in) - #{$i/8} * var(--anim-duration-in))
                        ease-in-out
                        calc(var(--anim-delay) + #{$i/8}s),
                    opacity
                        calc(var(--anim-duration-in) - #{$i/8} * var(--anim-duration-in))
                        ease-in-out
                        calc(var(--anim-delay) + #{$i/8}s);
            }
        }
    }
}
