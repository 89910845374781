
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                












































































































































































































































































.o-grid-assets {
    margin-bottom: 10vh;
}

.o-grid-assets__grid {
    --column-count: 1;

    display: grid;
    grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
    grid-column-gap: var(--grid-gutter);

    @media #{md("xs")} {
        --column-count: 2;
    }

    @media #{md("sm")} {
        --column-count: 3;
    }

    @media #{md("md")} {
        --column-count: 4;
    }
}

.o-grid-assets__cell {
    margin-top: var(--grid-gutter-half);
}

.o-grid-assets__empty {
    display: block;
    text-align: center;
}

.o-grid-assets__loading {
    position: fixed;
    bottom: calc( env(safe-area-inset-bottom) + var(--grid-gutter-half));

    transition: transform .2s $out-expo, opacity .2s $out-expo;
    transition-delay: 1.5s;
    transform: translateY(100%);
    opacity: 0;
    will-change: transform;

    &.-show {
        transition: transform .4s $out-expo, opacity .4s $out-expo;
        transition-delay: 0s;
        transform: translateY(0%);
        opacity: 1;
    }

    span {
        display: block;
        width: 2.4em;
        height: 2.4em;
        padding: 0.2em;
        background: #FFF;
        border-radius: 100%;
    }
    svg {
        width: 2em;
        height: 2em;
        stroke-dasharray: 4 4;
        animation: rotating 3s linear infinite;
        transform-origin: center;
    }
}
.o-grid-assets__bottom {
    display: block;
}

/*
.o-grid-assets__grid {
    grid-column-gap: var(--grid-gutter-half);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 0;
    grid-row-gap: 2px;

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media #{md("md")} {
        grid-template-columns: repeat(4, 1fr);
    }
}

.o-grid-assets__cell {
    //display: flex;
    align-items: center;

    .o-asset {
       padding-top: var(--grid-gutter-half);

    }
}
*/

.o-grid-assets__hover {
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    justify-content: center;
    pointer-events: none;
    mix-blend-mode: difference;
    transform: translate(0, -50%);
    transition: color .4s ease-out;

    .o-at__w {
        // width: 100%;
        // justify-content: center;
    }

    @media #{md("sm")} {
        right: $header-size;
        left: $header-size;
        width: auto;
        margin-right: 0;
        margin-left: 0;
    }
}
