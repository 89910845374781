
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

















































































































































































.o-select {
    --c-bg: #{$color-light};
    --c-text: #{$color-dark};
    --c-border: #{$color-dark};

    --border: 1px solid var(--c-border);

    color: $color-dark;

    &.is-focused {
        z-index: 1;

        .o-select__arrow {
            transform: rotate(180deg);
        }

        .o-select__list {
            z-index: 10;
            display: block;
        }
    }
}

.o-select__inner {
    border: var(--border);
}

.o-select__box {
    display: flex;
    align-items: center;
    padding: calc(1em - 1px) 3.125em calc(1em - 1px) 1em;
    color: var(--c-text);
    background-color: var(--c-bg);
    user-select: none;
    cursor: pointer;
}

.o-select__placeholder {
    display: block;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.o-select__delete {
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.o-select__arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.125em;
    height: 100%;
    cursor: pointer;
    transition: transform .3s ease-out;
}

.o-select__list {
    position: absolute;
    top: 100%;
    right: -1px;
    left: -1px;
    width: auto;
    max-height: 12em;
    @include reset-list;
    background-color: var(--c-bg);
    border-right: var(--border);
    border-bottom: var(--border);
    border-left: var(--border);
    overflow-y: auto;

    display: none;
}

.o-select__option {
}

.o-select__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .75em 1em;
    text-align: left;
    cursor: pointer;
    transition: background-color .2s ease-out;

    &:hover {
        background-color: $color-beige;
    }

    &.is-active {
        background-color: $color-peach;

        &:hover {
            background-color: $color-grey-light;

            .o-icon {
                transform: scale(1.2);
            }
        }
    }

    .o-icon {
        transition: transform .2s ease-out;
    }
}

