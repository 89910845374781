
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                














































































































































































































































.o-input-range {}

.o-input-range__slider {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    background-color: $color-dark;
}

.o-input-range__range {
    position: absolute;
    top: calc(50% -2px/2);
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color-blue-bright;
}

.o-input-range__btn {
    position: absolute;
    top: 0;
    left: 0;
}

.o-input-range__handle {
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-top: calc(-1.5em/2);
    background-color: $color-blue-bright;
    border-radius: 50%;
}

.o-input-range__value {
    position: absolute;
    top: 100%;
    left: calc(50% - 2em/2);
    width: 2em;
    display: block;
    margin-top: .5em;
    text-align: center;
}

