
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































































































// .p-collection {}

.p-collection__filter {
    --reveal-end: .6;

    margin-bottom: 1em;

    &.js-reveal {
        transform: translate(0, .5em);
        overflow: hidden;

        &:after {
            transform: scale(0, 1);
            transform-origin: 0 50%;
        }

        .c-btn__label {
            transform: translate(0, 100%);
        }

        &.is-visible {
            transform: translate(0);
            transition: transform .6s $in-out-circ;

            &:after {
                transform: scale(1);
                transition: transform .3s $in-out-circ .3s;
            }

            .c-btn__label {
                transform: translate(0);
                transition: transform .6s $in-out-circ;
            }
        }
    }
}

